import React, { useState, useEffect } from "react";
import axios from "axios";

const MatchResults = () => {
    const [matchedGroups, setMatchedGroups] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/match`)
            .then((res) => {
                console.log("📡 Fetched Groups Data:", res.data);
                setMatchedGroups(res.data);  // ✅ FIXED: `res.data` is the array itself
            })
            .catch((err) => console.error("❌ Error fetching matched groups: ", err.response?.data || err.message));
    }, []);

    return (
        <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-2xl font-bold mb-6 text-center">Matched Groups</h1>
            
            {matchedGroups.length === 0 ? (
                <p className="text-center text-gray-600">No groups found.</p>
            ) : (
                matchedGroups.map((group, index) => {
                    console.log("📡 Group Data: ", group);
                    console.log("Group time: ", group.time);
                    console.log("🔗 Teams Link: ", group.teamsLink);
                    console.log("📅 ICS File Path: ", group.icsFilePath);

                    // ✅ Fix time formatting
                     const formattedTime = group.time === "6" ? "6:00 AM"
                        : group.time === "12" ? "12:00 PM"
                        : "6:00 PM";

                    return (
                        <div key={index} className="mb-6 p-4 border rounded shadow-sm">
                            <h2 className="text-xl font-bold">Group {index + 1}: ${group.languagePair}</h2>
                            <p><strong>Meetup Time:</strong> {group.date} - {formattedTime}</p>
                            <p><strong>Meetup Location:</strong> {group.place}</p>

                            {/* 🔗 Teams Link */}
                            {group.teamsLink ? (
                                <p>
                                    <a href={group.teamsLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                                        Join Teams Meeting
                                    </a>
                                </p>
                            ) : (
                                <p className="text-gray-500">Teams link not available.</p>
                            )}

                            {/* 📅 Calendar Invite (.ICS File) */}
                            {group.icsFilePath ? (
                                <p>
                                    <a href={group.icsFilePath} download className="text-blue-500 underline">
                                        Download Calendar Invite
                                    </a>
                                </p>
                            ) : (
                                <p className="text-gray-500">Calendar invite not available.</p>
                            )}
                        </div>
                    );
                })
            )}
        </div>
    );
};

export default MatchResults;
