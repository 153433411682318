import { useState, useEffect } from "react";
import axios from "axios";

const MatchUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/users`
        );
        console.log(`api/users: ${process.env.REACT_APP_SERVER_URL}`);
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error.message);
      }
    };
    fetchUsers();
  }, []);

  const handleMatch = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/match`
      );
      alert(response.data.message || "Grouping successful!");
    } catch (error) {
      console.error("Error matching users:", error.message);
      alert("An error occurred. Check the console for details.");
    }
    setLoading(false);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Match Users</h2>
      <button
        onClick={handleMatch}
        className={`px-6 py-3 mb-4 bg-blue-500 text-white rounded ${
          loading ? "opacity-50 cursor-not-allowed" : ""
        }`}
        disabled={loading}
      >
        {loading ? "Processing..." : "Start Matching"}
      </button>
      <table className="w-full border">
        <thead>
          <tr>
            <th className="border p-2">Name</th>
            <th className="border p-2">Email</th>
            <th className="border p-2">Phone</th>
            <th className="border p-2">Zip Code</th>
            <th className="border p-2">Languages Learning</th>
            <th className="border p-2">Languages Teaching</th>
            {/* <th className="border p-2">Level Learning</th>
            <th className="border p-2">Level Teaching</th> */}
            <th className="border p-2">Interests</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td className="border p-2">{user.name}</td>
              <td className="border p-2">{user.email}</td>
              <td className="border p-2">{user.phone}</td>
              <td className="border p-2">{user.zipCode}</td>
              <td className="border p-2">{user.languagesLearning}</td>
              <td className="border p-2">{user.languagesTeaching}</td>
              {/* <td className="border p-2">{user.levelLearning}</td>
              <td className="border p-2">{user.levelTeaching}</td> */}
              <td className="border p-2">{user.interests.join(", ")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MatchUsers;
