import React from "react";

const Navbar = () => {
  return (
    <nav className="bg-blue-500 text-white p-4 flex justify-between items-center">
      <div className="text-xl font-bold">VibeMesh</div>
      <ul className="flex space-x-4">
        <li>
          <a href="/" className="hover:underline">
            🌐Home{" "}
          </a>
        </li>
        <li>
          <a href="/post" className="hover:underline">
            🏖Interests
          </a>
        </li>
       {/* <li>
          <a href="/feedback/list" className="hover:underline">
            🎐Reviews
          </a>
        </li> */}
        {/* <li>
         <a href="/users" className="hover:underline">
            Submit Preferences
          </a>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
