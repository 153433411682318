import React, { useState, useEffect } from "react";

const FeedbackList = () => {
  const [feedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://api.vibemesh.com/api/feedback/list")
      .then((res) => res.json())
      .then((data) => {
        setFeedback(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("❌ Error fetching feedback:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4">📝 User Feedback</h2>

      {loading ? (
        <p>Loading feedback...</p>
      ) : feedback.length === 0 ? (
        <p>No feedback available yet.</p>
      ) : (
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 p-2">Email</th>
              <th className="border border-gray-300 p-2">Date</th>
              <th className="border border-gray-300 p-2">Rating</th>
              <th className="border border-gray-300 p-2">Comments</th>
            </tr>
          </thead>
          <tbody>
            {feedback.map((item, index) => (
              <tr key={index} className="border border-gray-300">
                <td className="p-2">{item.email}</td>
                <td className="p-2">{new Date(item.meetupDate).toLocaleDateString()}</td>
                <td className="p-2">{item.rating}/5</td>
                <td className="p-2">{item.comments || "No comments provided"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FeedbackList;
