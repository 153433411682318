import React, { useState, useEffect } from "react";
import axios from "axios";

// ✅ List of Languages & Levels
const languages = [
  "English",
  "Spanish",
  "Korean",
  "German",
  "French",
  "Italian",
  "Polish",
  "Portuguese",
  "Russian",
  "Arabic",
  "Dutch",
  "Turkish",
  "Greek",
  "Hebrew",
  "Swedish",
  "Norwegian",
  "Thai",
  "Tagalog",
  "Japanese",
  "Vietnamese",
  "Mandarin",
  "Cantonese",
];
// const levels = ["Beginner", "Middle", "Advanced"];
const days = ["Wed", "Sat", "Sun"];
const timeMapping = { "6 AM CT": 6, "12 PM CT": 12, "6 PM CT": 18 };

const interestsOptions = [
  { label: "🌎 Exploring a new city or country", value: "Travel" },
  { label: "🎵 Attending a live concert", value: "Music" },
  { label: "🍜 Trying a new restaurant or recipe", value: "Food" },
  { label: "📚 Reading and discussion", value: "Books" },
  { label: "🎬 Watching movies or dramas", value: "Movies" },
];

// ✅ Detect User's Timezone
const detectUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC"; // Default to UTC if detection fails
};

// ✅ Convert CT Time to User's Local Time
// ✅ Convert CT Time to User's Local Time (Handles DST)
const convertToUserLocalTime = (hourCT, userTimezone) => {
  try {
    // ✅ Create a Date object in CT time (Central Time adjusts for DST automatically)
    const ctDate = new Date();
    ctDate.setHours(hourCT, 0, 0, 0); // Set time in CT (without manually adding +6 UTC)

    // ✅ Convert to user's local timezone (Handles DST automatically)
    return new Intl.DateTimeFormat("en-US", {
      timeZone: userTimezone,
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(ctDate);
  } catch (error) {
    console.error("❌ Error converting to user local time:", error.message);
    return `${hourCT}:00 CT`; // Fallback if error occurs
  }
};

const UserForm = () => {
  const [formData, setFormData] = useState({
    name: "", // ✅ 1. User's Name
    email: "", // ✅ 2. User's Email
    phone: "", // ✅ 3. User's Phone Number
    zipCode: "", // ✅ 4. User's Zip Code (for location-based matching)
    languagesLearning: [], // ✅ 5. Language the user wants to learn
    // levelLearning: [], // ✅ 6. Proficiency level in learning language
    languagesTeaching: [], // ✅ 7. Language the user can teach
    // levelTeaching: [], // ✅ 8. Proficiency level in teaching language
    availability: [], // ✅ 9. Availability (Days & Time)
    meetingPreference: "Offline", // ✅ 10. Meeting Preference (Offline/Online)
    notificationPreference: "Email", // ✅ 11. Notification Preference (Email/SMS)
    interests: [], // ✅ 12. User's Interests
    timezone: detectUserTimezone(), // ✅ Auto-detect user timezone
  });

  const [localTimes, setLocalTimes] = useState({}); // ✅ Store converted local times

  /**
   * ✅ Update local times based on user's timezone
   */
  useEffect(() => {
    const userTimezone = detectUserTimezone();
    const convertedTimes = Object.keys(timeMapping).reduce((acc, timeLabel) => {
      acc[timeLabel] = convertToUserLocalTime(
        timeMapping[timeLabel],
        userTimezone
      );
      return acc;
    }, {});

    setLocalTimes(convertedTimes);
    setFormData((prevData) => ({
      ...prevData,
      timezone: userTimezone,
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAvailabilityChange = (day, timeLabel) => {
    const timeValue = timeMapping[timeLabel];
    const availabilityKey = `${day}-${timeValue}`;
    const availabilitySet = new Set(formData.availability);

    if (availabilitySet.has(availabilityKey)) {
      availabilitySet.delete(availabilityKey);
    } else {
      availabilitySet.add(availabilityKey);
    }

    setFormData({ ...formData, availability: Array.from(availabilitySet) });
  };

  const handleInterestsChange = (selectedInterest) => {
    setFormData((prevData) => {
      const interestValue =
        interestsOptions.find((i) => i.label === selectedInterest)?.value ||
        selectedInterest;

      const newInterests = prevData.interests.includes(interestValue)
        ? prevData.interests.filter((i) => i !== interestValue)
        : [...prevData.interests, interestValue];

      return { ...prevData, interests: newInterests };
    });
  };

  // ✅ Handle multi-select for languages and levels
  const handleMultiSelect = (type, value) => {
    setFormData((prevData) => {
      const updated = prevData[type].includes(value)
        ? prevData[type].filter((item) => item !== value)
        : [...prevData[type], value];

      return { ...prevData, [type]: updated };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("🔄 Sending Data to API:", JSON.stringify(formData, null, 2));

    if (!formData.availability || formData.availability.length === 0) {
      alert("⚠️ Please select at least one available time.");
      return;
    }

    try {
      await axios.post("https://api.vibemesh.com/api/users", formData, {
        headers: { "Content-Type": "application/json" },
      });
      alert("✅ Your preferences have been submitted successfully! You'll get an email/sms once matched. Thank you.");
      setFormData({
        name: "",
        email: "",
        phone: "",
        zipCode: "",
        languagesLearning: [],
        // levelLearning: [],
        languagesTeaching: [],
        // levelTeaching: [],
        availability: [],
        interests: [],
        meetingPreference: "Offline",
        notificationPreference: "Email",
        timezone: detectUserTimezone(), // ✅ Reset timezone
      });
    } catch (error) {
      console.error(
        "❌ Submission error:",
        error.response?.data || error.message
      );
      alert("❌ Submission failed. Please try again later.");
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto mt-10 p-8 bg-white rounded-lg shadow-md">
      <form onSubmit={handleSubmit}>
        {/* Interests Selection */}
        <div className="mb-4">
          <label className="block font-bold mb-2 text-lg">
            ⏳️ What would be your topics to talk about during language exchange
            meetups?
          </label>
          <div className="grid grid-cols-2 gap-2">
            {interestsOptions.map((interest) => (
              <label
                key={interest.value}
                className="flex items-center space-x-2 text-lg"
              >
                <input
                  type="checkbox"
                  checked={formData.interests.includes(interest.value)}
                  onChange={() => handleInterestsChange(interest.label)}
                  // disabled={
                  //   formData.interests.length >= 3 &&
                  //   !formData.interests.includes(interest.value)
                  // }
                />
                <span>{interest.label}</span>
              </label>
            ))}
          </div>

          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your Name"
            className="w-full p-3 border rounded mb-4"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email Address"
            className="w-full p-3 border rounded mb-4"
            required
          />
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone Number (Optional: +15535575588)"
            className="w-full p-3 border rounded mb-4"
            required={formData.notificationPreference === "SMS"}
          />
          <input
            type="text"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleChange}
            placeholder="Zip Code"
            className="w-full p-3 border rounded mb-4"
            required
          />
        </div>

        {/* Language Selection */}
        {/* ✅ Languages You Want to Learn */}
        <div className="mb-4">
          <label className="block font-bold mb-2">
            Languages You Want to Learn
          </label>
          <div className="grid grid-cols-2 gap-2">
            {languages.map((lang) => (
              <label
                key={`learn-${lang}`}
                className="flex items-center space-x-2"
              >
                <input
                  type="checkbox"
                  checked={formData.languagesLearning.includes(lang)}
                  onChange={() => handleMultiSelect("languagesLearning", lang)}
                />
                <span>{lang}</span>
              </label>
            ))}
          </div>
        </div>

        {/* ✅ Your Proficiency Levels for Learning */}
        {/* <div className="mb-4">
  <label className="block font-bold mb-2">Your Proficiency Levels (Learning)</label>
  <div className="flex flex-wrap gap-4">
    {levels.map((level) => (
      <label key={`learn-level-${level}`} className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={formData.levelsLearning.includes(level)}
          onChange={() => handleMultiSelect("levelsLearning", level)}
        />
        <span>{level}</span>
      </label>
    ))}
  </div>
</div> */}

        {/* ✅ Languages You Can Teach */}
        <div className="mb-4">
          <label className="block font-bold mb-2">
            Languages You Can Teach
          </label>
          <div className="grid grid-cols-2 gap-2">
            {languages.map((lang) => (
              <label
                key={`teach-${lang}`}
                className="flex items-center space-x-2"
              >
                <input
                  type="checkbox"
                  checked={formData.languagesTeaching.includes(lang)}
                  onChange={() => handleMultiSelect("languagesTeaching", lang)}
                />
                <span>{lang}</span>
              </label>
            ))}
          </div>
        </div>

        {/* ✅ Your Proficiency Levels for Teaching */}
        {/* <div className="mb-4">
  <label className="block font-bold mb-2">Your Proficiency Levels (Teaching)</label>
  <div className="flex flex-wrap gap-4">
    {levels.map((level) => (
      <label key={`teach-level-${level}`} className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={formData.levelsTeaching.includes(level)}
          onChange={() => handleMultiSelect("levelsTeaching", level)}
        />
        <span>{level}</span>
      </label>
    ))}
  </div>
</div> */}

        {/* Availability Selection */}
        <div className="mb-4">
          <label className="block font-bold mb-2">Select Availability</label>
         <p className="text-sm text-gray-500 italic mt-2">
  * Times shown inside () are adjusted to your local timezone: <strong>{formData.timezone}</strong>
</p>
            {days.map((day) => (
            <div key={day} className="mb-2">
              <p className="font-semibold">{day}</p>
              {Object.keys(timeMapping).map((timeLabel) => (
                <label
                  key={`${day}-${timeLabel}`}
                  className="inline-flex items-center mr-4"
                >
                  <input
                    type="checkbox"
                    onChange={() => handleAvailabilityChange(day, timeLabel)}
                    checked={formData.availability.includes(
                      `${day}-${timeMapping[timeLabel]}`
                    )}
                    className="mr-2"
                  />
                  {timeLabel} ({localTimes[timeLabel] || "Loading..."}){" "}
                  {/* ✅ Display Local Time */}
                </label>
              ))}
            </div>
          ))}
        </div>

        {/* Meeting Preference */}
        <div className="mb-4">
          <label className="block font-bold mb-2">Meeting Preference</label>
          <select
            name="meetingPreference"
            value={formData.meetingPreference}
            onChange={handleChange}
            className="w-full p-3 border rounded"
            required
          >
            <option value="Offline">Offline</option>
            <option value="Online">Online</option>
          </select>
        </div>

        {/* Notification Preference */}
        <div className="mb-4">
          <label className="block font-bold mb-2">
            Notification Preference: If you choose SMS, you agree to receive SMS
            notifications from VibeMesh. Message & data rates may apply. Reply
            STOP to unsubscribe.
          </label>
          <select
            name="notificationPreference"
            value={formData.notificationPreference}
            onChange={handleChange}
            className="w-full p-3 border rounded"
            required
          >
            <option value="Email">Email</option>
            <option value="SMS">SMS</option>
          </select>

          {/* SMS Opt-in Checkbox (Required for Twilio Verification) */}
          {formData.notificationPreference === "SMS" && (
            <div className="mt-2">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  name="smsOptIn"
                  checked={formData.smsOptIn || false}
                  onChange={() =>
                    setFormData((prevData) => ({
                      ...prevData,
                      smsOptIn: !prevData.smsOptIn,
                    }))
                  }
                  required
                />
                <span className="ml-2 text-gray-700">
                  I agree to receive SMS notifications from VibeMesh. Message &
                  data rates may apply. Reply STOP to unsubscribe.
                </span>
              </label>
            </div>
          )}
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-3 rounded hover:bg-blue-600 transition"
        >
          Submit Preferences
        </button>
      </form>
    </div>
  );
};

export default UserForm;
