import React, { useState } from "react";
import AdminLogin from "./AdminLogin";
import MatchUsers from "./MatchUsers";
import MatchResults from "./MatchResults";

const AdminDashboard = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [activeTab, setActiveTab] = useState("matchUsers");

  if (!authenticated) return <AdminLogin onSuccess={() => setAuthenticated(true)} />;

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-4">Admin Dashboard</h1>

      <div className="flex mb-4 space-x-4">
        <button
          onClick={() => setActiveTab("matchUsers")}
          className={`px-4 py-2 rounded ${
            activeTab === "matchUsers" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          Match Users
        </button>
        <button
          onClick={() => setActiveTab("matchResults")}
          className={`px-4 py-2 rounded ${
            activeTab === "matchResults" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          Match Results
        </button>
      </div>

      {activeTab === "matchUsers" && <MatchUsers />}
      {activeTab === "matchResults" && <MatchResults />}
    </div>
  );
};

export default AdminDashboard;
