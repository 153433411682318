import React from "react";
import { useNavigate } from "react-router-dom";

const SubscribeSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-lg mx-auto mt-10 p-6 bg-white rounded-lg shadow-md text-center">
      <h2 className="text-2xl font-bold text-green-600">🎉 Payment Successful!</h2>
      <p className="mt-4 text-lg">Your subscription is now active. Check your email for confirmation.</p>
      <button 
        className="mt-6 px-4 py-2 bg-blue-600 text-white rounded"
        onClick={() => navigate("/")}
      >
        Go to Home
      </button>
    </div>
  );
};

export default SubscribeSuccess;
