import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const categories = ["All", "Travel", "Food", "Books", "Music", "Movies"];

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // ✅ Fetch Blog Posts
  const fetchPosts = useCallback(async () => {
    setLoading(true);
    setError("");

    try {
      const response = await axios.get(
        `https://api.vibemesh.com/api/rss/post${
          selectedCategory !== "All" ? `?category=${selectedCategory}` : ""
        }`
      );

      if (Array.isArray(response.data.posts) && response.data.posts.length > 0) {
        setPosts(response.data.posts);
      } else {
        setPosts([]);
        setError("No posts available.");
      }
    } catch (error) {
      console.error("❌ Error fetching blog posts:", error.message);
      setPosts([]);
      setError("Failed to load posts. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [selectedCategory]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  return (
    <div className="max-w-6xl mx-auto my-10 px-4">
      <h2 className="text-4xl font-bold text-center mb-6">📖 Interests - Use as talking topics during meetups</h2>

      {/* Filter Categories */}
      <div className="flex flex-wrap justify-center gap-3 mb-6">
        {categories.map((cat) => (
          <button
            key={cat}
            className={`px-5 py-2 rounded-lg text-lg font-medium transition-all duration-200 ${
              selectedCategory === cat
                ? "bg-blue-600 text-white shadow-lg"
                : "bg-gray-200 hover:bg-gray-300"
            }`}
            onClick={() => setSelectedCategory(cat)}
          >
            {cat}
          </button>
        ))}
      </div>

      {/* ✅ Show Loading State */}
      {loading && (
        <p className="text-center text-gray-500 text-xl col-span-full">
          Loading posts...
        </p>
      )}

      {/* ✅ Show Error Message */}
      {error && !loading && (
        <p className="text-center text-red-500 text-xl col-span-full">{error}</p>
      )}

      {/* Blog Posts Grid */}
      {!loading && !error && posts.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {posts.map((post) => (
            <Link
              to={`/post/${post._id}`}
              key={post._id}
              className="block bg-white rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 overflow-hidden"
            >
              {/* ✅ Main Image from imageUrls array */}
              <img
                src={post.imageUrls && post.imageUrls.length > 0 ? post.imageUrls[0] : "https://source.unsplash.com/featured/?blog"}
                alt={post.title}
                className="w-full h-56 object-cover rounded-t-lg"
                onError={(e) => {
                  e.target.src = "https://source.unsplash.com/featured/?blog";
                }}
              />
              <div className="p-5">
                <h3 className="text-2xl font-semibold">{post.title}</h3>
                <p className="text-blue-500 text-md font-medium mt-1">{post.category}</p>
                <h5 className="mt-3 text-lg text-gray-700 line-clamp-3">
                  {post.content.replace(/<style>[\s\S]*?<\/style>/g, "").replace(/<\/?[^>]+(>|$)/g, "").substring(0, 200)}...
                </h5>
                <h6 className="mt-3 text-blue-500 font-semibold">Read more →</h6>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Blog;
