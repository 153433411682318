import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const BlogPost = () => {
  const { postID } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPost = async () => {
      setError("");
      setPost(null);

      try {
        const response = await axios.get(
          `https://api.vibemesh.com/api/rss/post/${postID}?timestamp=${Date.now()}`
        );

        if (response.data && response.data.post) {
          setPost(response.data.post);
        } else {
          setPost(null);
          setError("Post not found.");
        }
      } catch (error) {
        console.error("❌ Error fetching blog post:", error.message);
        setPost(null);
        setError("Failed to load post. Please try again later.");
      }
    };

    fetchPost();
  }, [postID]);

  // ✅ Show Loading State
  if (!post && !error) return <p className="text-center text-xl mt-10">Loading...</p>;

  // ✅ Show Error Message
  if (error) return <p className="text-center text-red-500 text-xl mt-10">{error}</p>;

  // ✅ Ensure `itemNames`, `imageUrls`, and `content` exist before rendering
  if (!post.itemNames || !post.imageUrls || !post.content) return <p>No content available.</p>;

  // ✅ Extract item names using Regex
  const itemNames = post.content.match(/<h3>(.*?)<\/h3>/g)?.map(name => name.replace(/<\/?h3>/g, "")) || [];

  // ✅ Extract image URLs using Regex (ensures no duplicate images)
  let imageUrls = post.content.match(/<img[^>]+src="([^">]+)"/g)?.map(img => img.match(/src="([^">]+)"/)[1]) || [];
  imageUrls = [...new Set(imageUrls)]; // ✅ Remove duplicate images

  // ✅ Ensure correct description splitting AFTER `<h3>` and `<img>`
  let rawSections = post.content.split(/<\/h3>\s*<img[^>]*>/g);

  // ✅ Remove first broken content (e.g., `.responsive-img` appearing at the start)
  if (rawSections[0]?.includes(".responsive-img")) rawSections.shift();

  // ✅ Clean descriptions (remove extra HTML, inline styles, and excessive spacing)
  const descriptions = rawSections.map(desc => 
    desc
      .replace(/<\/?[^>]+(>|$)/g, "") // ✅ Remove all HTML tags
      .replace(/\{[^}]*\}/g, "") // ✅ Remove inline CSS `{ width: 100% }`
      .replace(/\s{2,}/g, " ") // ✅ Remove excessive spaces
      .trim()
  ).filter(desc => desc.length > 10); // ✅ Remove empty or short descriptions

  // ✅ Fix Alignment: Ensure itemNames, images, and descriptions have 1:1 mapping
  const minLength = Math.min(itemNames.length, imageUrls.length, descriptions.length);
  const finalItemNames = itemNames.slice(0, minLength);
  const finalImageUrls = imageUrls.slice(0, minLength);
  const finalDescriptions = descriptions.slice(0, minLength);

  // ✅ Debugging Logs (Check ItemName, Image, Description)
  console.log("Item Names:", finalItemNames);
  console.log("Images:", finalImageUrls);
  console.log("Descriptions:", finalDescriptions);

  return (
    <div className="max-w-4xl mx-auto my-10 p-6 bg-white shadow-lg rounded-lg">
      {/* ✅ Blog Title */}
      <h1 className="text-4xl font-bold mb-4 text-center">{post.title}</h1>

      {/* ✅ Category & Published Date */}
      <p className="text-gray-500 text-md text-center mb-6">
        {post.category} • {new Date(post.publishedAt).toLocaleDateString()}
      </p>

      {/* ✅ Blog Content with Strict 1:1 Matching */}
      <div className="prose prose-lg leading-relaxed space-y-8">
        {finalItemNames.map((itemName, index) => {
          const imageUrl = finalImageUrls[index] || "https://source.unsplash.com/featured/?blog";
          const description = finalDescriptions[index] || "No description available.";

          return (
            <div key={index} className="mb-10">
              {/* ✅ Item Name (h3) */}
              <h3 className="text-2xl font-semibold text-center mb-3">{itemName}</h3>

              {/* ✅ Matching Image Below Item Name */}
              <img
                src={imageUrl}
                alt={itemName}
                className="flex justify-center w-full md:w-4/5 rounded-lg shadow object-cover h-[300px] max-h-[300px]"
                onError={(e) => {
                  e.target.src = "https://source.unsplash.com/featured/?blog";
                }}
              />

              {/* ✅ Matching Description Below Image */}
              <h5 className="mt-4 text-lg leading-relaxed">{description}</h5>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BlogPost;
