import React, { useState } from "react";
import axios from "axios";

const UpdateGroupForm = () => {
  const [groupId, setGroupId] = useState("");
  const [place, setPlace] = useState("");
  const [time, setTime] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!groupId) {
      setResponseMessage("Group ID is required.");
      return;
    }

    try {
      const response = await axios.put(
        "https://api.vibemesh.com/api/admin/update-group",
        { groupId, place, time }
      );

      setResponseMessage(response.data.message);
    } catch (error) {
      console.error("Error updating group:", error.message);
      setResponseMessage("Failed to update group. Please try again.");
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Update Group Details</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="groupId" className="block font-medium mb-2">
            Group ID
          </label>
          <input
            type="text"
            id="groupId"
            value={groupId}
            onChange={(e) => setGroupId(e.target.value)}
            className="border p-2 w-full"
            required
          />
        </div>
        <div>
          <label htmlFor="place" className="block font-medium mb-2">
            New Place
          </label>
          <input
            type="text"
            id="place"
            value={place}
            onChange={(e) => setPlace(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <div>
          <label htmlFor="time" className="block font-medium mb-2">
            New Time
          </label>
          <input
            type="datetime-local"
            id="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Update Group
        </button>
      </form>
      {responseMessage && (
        <p className="mt-4 text-green-500">{responseMessage}</p>
      )}
    </div>
  );
};

export default UpdateGroupForm;
