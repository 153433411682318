import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import UserForm from "./components/UserForm";
import Footer from "./components/Footer";
import AdminDashboard from "./admin/AdminDashboard";
import MatchUsers from "./admin/MatchUsers";
import MatchResults from "./admin/MatchResults";
import NearbyPlaces from "./admin/NearbyPlaces";
import UpdateGroupForm from "./admin/UpdateGroupForm";
import Subscribe from "./pages/Subscribe";
import SubscribeSuccess from "./pages/SubscribeSuccess";
import SubscribeCancel from "./pages/SubscribeCancel";
import Feedback from "./pages/Feedback"; // Import Feedback page
import FeedbackList from "./pages/FeedbackList";
import Blog from "./pages/Blog"; // Import Blog page
import BlogPost from "./pages/BlogPost"; // Import BlogBlog page for Blog Details Page
import "./App.css";  // Import Global Styles

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex-grow">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/admin" element={<AdminDashboard />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/subscribe/success" element={<SubscribeSuccess />} />
            <Route path="/subscribe/cancel" element={<SubscribeCancel />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/feedback/list" element={<FeedbackList />} />
            <Route path="/post" element={<Blog />} />
            <Route path="/post/:postID" element={<BlogPost />} />
            <Route path="/admin/match" element={<MatchUsers />} />
            <Route path="/users" element={<UserForm />} />
            <Route path="/admin/results" element={<MatchResults />} />
            <Route
              path="/admin/places"
              element={<NearbyPlaces lat={44.9778} lng={-93.265} />}
            />{" "}
            {/* Example coordinates */}
            <Route path="/admin/update-group" element={<UpdateGroupForm />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
