import React from "react";
import UserForm from "./UserForm";

const Home = () => {
  return (
    <div className="p-6 w-full max-w-screen-xl mx-auto">
      {/* Header Section */}
      <header className="text-center mb-10">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-4">
          🌍 VibeMesh - Beyond Language Exchange
        </h1>
        <h5 className="text-lg text-gray-700">
          Connect, Learn, and Grow with People Who Share Your Passion for Languages and Culture.
        </h5>
      </header>

      {/* Intro Video */}
      <div className="flex justify-center items-center mb-10">
        <div className="relative w-full max-w-4xl mx-auto aspect-video overflow-hidden">
          <iframe
            className="w-full h-full rounded-lg shadow-lg"
            src="https://www.youtube.com/embed/h2F1KacQLJ8"
            title="VibeMesh Video"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* Signup Form */}
      <section className="mb-10">
        <UserForm />
      </section>

      {/* Feature Boxes Section */}
      <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
        {/* Box 1: Find Your Language Partner */}
        <div className="bg-blue-500 text-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-2">🔍 Find Your Language Partner</h2>
          <h5 className="text-lg">
            Get matched with language learners and native speakers who align with your learning goals.
          </h5>
        </div>

        {/* Box 2: Join In-Person or Online Meetups */}
        <div className="bg-green-500 text-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-2">📍 Meet In-Person or Online</h2>
          <h5 className="text-lg">
            Join structured meetups at cafes, libraries, or online through Microsoft Teams.
          </h5>
        </div>

        {/* Box 3: Connect Through Culture */}
        <div className="bg-red-500 text-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-2">🌎 Connect Through Culture</h2>
          <h5 className="text-lg">
            Explore cultural events, music, and food experiences while practicing languages.
          </h5>
        </div>

        {/* Box 4: Flexible Scheduling */}
        <div className="bg-purple-500 text-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-2">📅 Flexible Scheduling</h2>
          <h5 className="text-lg">
            Choose your preferred day and time. We match you with others who fit your availability.
          </h5>
        </div>

        {/* Box 5: Safe & Supportive Environment */}
        <div className="bg-yellow-500 text-gray-900 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-2">🛡 Safe & Supportive</h2>
          <h5 className="text-lg">
            We prioritize safe, public venues for in-person meetups and provide structured guidelines.
          </h5>
        </div>

        {/* Box 6: Make Every Meetup Count */}
        <div className="bg-indigo-500 text-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-2">🎯 Make Every Meetup Count</h2>
          <h5 className="text-lg">
            Prepare for discussions with curated topics, games, and study materials.
          </h5>
        </div>
      </section>

      {/* How It Works */}
      <section className="mt-12 p-8 bg-gray-100 rounded-lg shadow-lg max-w-screen-lg mx-auto">
        <h2 className="text-3xl font-bold text-center mb-6">🛠 How It Works</h2>
        <ul className="list-disc list-inside text-lg text-gray-800 space-y-4">
          <li>📋 **Step 1:** Fill out your preferences – choose the language you want to learn and teach.</li>
          <li>🤝 **Step 2:** Get matched with a group of 3-6 people who share your learning goals.</li>
          <li>📅 **Step 3:** Receive an email/SMS with the time and location of your meetup.</li>
          <li>💬 **Step 4:** Join discussions via Microsoft Teams or meet in person at public venues.</li>
        </ul>
      </section>

      {/* Why Join Us? */}
      <section className="mt-12 p-8 bg-gray-100 rounded-lg shadow-lg max-w-screen-lg mx-auto">
        <h2 className="text-3xl font-bold text-center mb-6">🎉 Why Join Us?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="p-6 bg-white shadow-lg rounded-lg">
            <h3 className="text-2xl font-semibold text-blue-600">🌟 Expand Your Social Circle</h3>
            <h5 className="text-gray-700 text-lg">
              Connect with like-minded individuals and build meaningful relationships.
            </h5>
          </div>

          <div className="p-6 bg-white shadow-lg rounded-lg">
            <h3 className="text-2xl font-semibold text-green-600">📚 Learn More Effectively</h3>
            <h5 className="text-gray-700 text-lg">
              Improve your skills through structured meetups and real-life conversations.
            </h5>
          </div>

          <div className="p-6 bg-white shadow-lg rounded-lg">
            <h3 className="text-2xl font-semibold text-red-600">🎭 Experience Cultural Exchange</h3>
            <h5 className="text-gray-700 text-lg">
              Explore different cultures through language, traditions, and social activities.
            </h5>
          </div>

          <div className="p-6 bg-white shadow-lg rounded-lg">
            <h3 className="text-2xl font-semibold text-purple-600">💼 Professional Growth</h3>
            <h5 className="text-gray-700 text-lg">
              Boost your career prospects by developing multilingual skills in a professional setting.
            </h5>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
