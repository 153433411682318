import React, { useState, useEffect } from "react";
import axios from "axios";

const NearbyPlaces = ({ groupId }) => {
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchNearbyPlaces = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://vibemsh.com/api/places/nearby",
          {
            params: {
              groupId, // Pass groupId as a parameter if necessary
            },
          }
        );
        console.log("Fetched places:", response.data); // Debug log
        setPlaces(response.data); // Set places state with response data
      } catch (error) {
        console.error("Error fetching nearby places:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNearbyPlaces();
  }, [groupId]);

  const handleSave = async () => {
    if (!selectedPlace || !time) {
      alert("Please select a place and time.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.vibemesh.com/api/admin/save-place",
        {
          groupId,
          place: selectedPlace.name,
          time,
        }
      );
      alert("Place and time saved successfully!");
      console.log("Save response:", response.data); // Debug log for save
    } catch (error) {
      console.error("Error saving place and time:", error.message);
      alert("Failed to save place and time.");
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Nearby Places</h2>
      {loading ? (
        <p>Loading places...</p>
      ) : (
        <div>
          <ul>
            {places.map((place, index) => (
              <li
                key={index}
                className={`p-2 border mb-2 ${
                  selectedPlace?.name === place.name ? "bg-blue-100" : ""
                }`}
                onClick={() => setSelectedPlace(place)}
              >
                <strong>{place.name}</strong> - {place.address} (Rating:{" "}
                {place.rating || "N/A"})
              </li>
            ))}
          </ul>
          <div className="mt-4">
            <label className="block mb-2">Select Time:</label>
            <input
              type="datetime-local"
              className="border p-2 w-full"
              value={time}
              onChange={(e) => setTime(e.target.value)}
            />
          </div>
          <button
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleSave}
          >
            Save Place and Time
          </button>
        </div>
      )}
    </div>
  );
};

export default NearbyPlaces;
