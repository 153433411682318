import axios from "axios";
import { useState } from "react";

const Feedback = () => {
    const [formData, setFormData] = useState({
        email: "",
        meetupDate: "",
        rating: "",
        comments: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };    

   const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post("https://api.vibemesh.com/api/feedback/submit", formData);

        if (response.data.success) {
            alert("✅ Feedback submitted successfully!");
            setFormData({ email: "", meetupDate: "", rating: "", comments: "" });
        } else {
            alert("⚠️ Error submitting feedback.");
        }
    } catch (error) {
        console.error("❌ Error submitting feedback:", error);
        alert("❌ Failed to submit feedback.");
    }
};
    
    
    

    return (
        <div className="max-w-md mx-auto my-10 p-6 bg-white shadow-md rounded">
            <h2 className="text-2xl font-bold mb-4">📢 Share Your Feedback</h2>
            <form onSubmit={handleSubmit}>
                <label className="block font-bold">Email</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full p-2 border rounded mb-4"
                />

                <label className="block font-bold">Meetup Date</label>
                <input
                    type="date"
                    name="meetupDate"
                    value={formData.meetupDate}
                    onChange={handleChange}
                    required
                    className="w-full p-2 border rounded mb-4"
                />

                <label className="block font-bold">Rating (1-5)</label>
                <select
                    name="rating"
                    value={formData.rating}
                    onChange={handleChange}
                    required
                    className="w-full p-2 border rounded mb-4"
                >
                    <option value="">Select Rating</option>
                    <option value="1">😞 1 - Poor</option>
                    <option value="2">😐 2 - Fair</option>
                    <option value="3">🙂 3 - Good</option>
                    <option value="4">😊 4 - Very Good</option>
                    <option value="5">🤩 5 - Excellent</option>
                </select>

                <label className="block font-bold">Comments</label>
                <textarea
                    name="comments"
                    value={formData.comments}
                    onChange={handleChange}
                    rows="4"
                    className="w-full p-2 border rounded mb-4"
                ></textarea>

                <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded">
                    Submit Feedback
                </button>
            </form>
        </div>
    );
};

export default Feedback;
