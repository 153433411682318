import React from "react";
import { useNavigate } from "react-router-dom";

const SubscribeCancel = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-lg mx-auto mt-10 p-6 bg-white rounded-lg shadow-md text-center">
      <h2 className="text-2xl font-bold text-red-600">❌ Payment Canceled</h2>
      <p className="mt-4 text-lg">No worries! You can try again anytime.</p>
      <button 
        className="mt-6 px-4 py-2 bg-blue-600 text-white rounded"
        onClick={() => navigate("/subscribe")}
      >
        Back to Subscription Page
      </button>
    </div>
  );
};

export default SubscribeCancel;
