import React, { useEffect, useState } from "react"; // ✅ Added useEffect & useState
import axios from "axios";
import { useSearchParams } from "react-router-dom"; // ✅ For grabbing query params

const Subscribe = () => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId"); // ✅ Dynamically pulled from URL: /subscribe?userId=abc123
  const [userEmail, setUserEmail] = useState(""); // ✅ Email will be fetched from DB
  
  // ✅ On load, fetch email from backend using userId
  useEffect(() => {
    if (userEmail) console.log("✅ Loaded user email:", userEmail);
    const fetchUserEmail = async () => {
      if (!userId) return;

      try {
        const res = await axios.get(`https://api.vibemesh.com/api/users/${userId}`); // ✅ Backend route returns user email
        setUserEmail(res.data.email); // ✅ Store email in state
      } catch (error) {
        console.error("❌ Error fetching user email:", error.message);
      }
    };

    fetchUserEmail();
  }, [userEmail, userId]);

  // ✅ Handle payment submission
  const handlePayment = async (plan) => {
    console.log("🔹 Sending plan:", plan);

    if (!userEmail) {
      alert("⚠️ User email not loaded. Please try again.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.vibemesh.com/api/payments/create-checkout-session",
        { plan, email: userEmail }, // ✅ Use fetched email
        { headers: { "Content-Type": "application/json" } }
      );

      console.log("✅ Received Stripe session URL:", response.data.url);
      window.location.href = response.data.url;
    } catch (error) {
      console.error("❌ Payment Error:", error.response?.data || error.message);
      alert("❌ Payment failed. Please try again.");
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">Upgrade Your Membership</h1>
      <p className="mb-4">Choose a plan to continue attending meetups!</p>

      <div className="space-y-4">
        {/* ✅ Trial Plan */}
        <div className="p-4 border rounded">
          <h2 className="font-bold">One-time Trial: $5.90</h2>
          <p>Join one more meetup before subscribing.</p>
          <button
            onClick={() => handlePayment("trial")}
            className="bg-blue-500 text-white px-4 py-2 rounded mt-2 hover:bg-blue-600"
          >
            Pay $5.90
          </button>
        </div>

        {/* ✅ Monthly Plan */}
        <div className="p-4 border rounded">
          <h2 className="font-bold">Monthly Plan: $9.90</h2>
          <p>Attend up to 4 meetups per month.</p>
          <button
            onClick={() => handlePayment("monthly")}
            className="bg-blue-500 text-white px-4 py-2 rounded mt-2 hover:bg-blue-600"
          >
            Subscribe $9.90/month
          </button>
        </div>

        {/* ✅ Yearly Plan */}
        <div className="p-4 border rounded">
          <h2 className="font-bold">Yearly Plan: $99</h2>
          <p>Attend up to 50 meetups per year.</p>
          <button
            onClick={() => handlePayment("yearly")}
            className="bg-blue-500 text-white px-4 py-2 rounded mt-2 hover:bg-blue-600"
          >
            Subscribe $99/year
          </button>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
