import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-4 text-center">
      <div class="text-sm text-gray-600 mt-6">
  <p>
    By signing up, you agree to receive SMS notifications from VibeMesh regarding your language exchange meetups.
    Message & data rates may apply. Reply STOP to unsubscribe.
  </p>
</div>
      <a href="/privacy-policy.html">Privacy Policy</a>
      <h5>&copy; {new Date().getFullYear()} Please contact us at hello@vibemesh.com for questions. VibeMesh. All rights reserved.</h5>
    </footer>
  );
};

export default Footer;
